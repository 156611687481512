import { useCallback, useEffect } from 'react'

import { PrescriptionProduct } from '../../services/medical/getMedicalPrescriptions'
import { OrderItem, VisitStatus } from '../../services/medical/types'
import { Flex, H2, List, Section } from '../../ui'
import { CartBuilder } from '../CartBuilder/CartBuilder'
import McGenerator from '../McGenerator/McGenerator'
import { PrescriptionsList } from '../Prescriptions/PrescriptionsList'
import { usePrescriptionsContext } from '../Prescriptions/PrescriptionsProvider'
import { ActionButtons } from './ActionButtons'
import { BmiCalculator } from './BmiCalculator'
import { ConsultationStatus } from './ConsultationStatus'
import { OrderDetails } from './OrderDetails'
import { usePlatoContext } from './PlatoProvider'
import { UserDetails } from './UserDetails'

const CONSULTATION_CATEGORY_SLUG = 'consultation'

export const VisitComponent = () => {
  const { order, status } = usePlatoContext()
  const { addToPrescriptions, currentPrescriptions } = usePrescriptionsContext()

  const shouldEnableCartBuilder =
    !order.isReconsultation &&
    (order.orderItems?.[0].product.category?.slug ===
      CONSULTATION_CATEGORY_SLUG ||
      [VisitStatus.Rejected, VisitStatus.Approved].includes(
        status as VisitStatus,
      ))

  const mapOrderItemToPrescription = (
    product: OrderItem,
  ): PrescriptionProduct => {
    return {
      isSubscription: product.product.plan.type === 'recurring',
      requiresPrescription: product.product.requiresPrescription,
      category: product.product.category,
      instructions: {
        frequency: '',
        remarks: product.product.description,
        quantityPrescribed: String(product.quantity),
      },
      name: product.product.name,
      slug: product.product.slug,
      status: 'draft',
      refProductId: product.product.id,
    }
  }
  const initPrescriptions = useCallback(() => {
    if (order?.orderItems?.length > 0) {
      order.orderItems.map(mapOrderItemToPrescription).map(addToPrescriptions)
    }
  }, [addToPrescriptions, order])

  useEffect(() => {
    if (currentPrescriptions.length > 0) {
      return
    }

    if (status === 'registered') {
      initPrescriptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return (
    <Section className="bg-neutral2 w-full h-full px-8" padding="py-8">
      <List>
        <H2>Consultation Details</H2>
        <UserDetails />
        <McGenerator />
        <PrescriptionsList />
        <BmiCalculator />
        <ConsultationStatus />
        {shouldEnableCartBuilder ? (
          <CartBuilder />
        ) : (
          <List>
            <Flex>
              <OrderDetails />
              <ActionButtons />
            </Flex>
          </List>
        )}
      </List>
    </Section>
  )
}
