export const bmiCalculator = (bmiWeight: number, bmiHeight: number) => {
  if (bmiHeight && bmiWeight) {
    const heightInMeter = bmiHeight / 100
    const bmiValue = parseFloat(
      (bmiWeight / Math.pow(heightInMeter, 2)).toFixed(2),
    )
    return bmiValue
  }
  return undefined
}
