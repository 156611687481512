import {
  createAxiosInstance,
  getToken,
  saveBmiAnswerProps,
  SelectedTreatmentProduct,
} from '..'

export type UpdateTreatmentContext = {
  treatmentPlanId?: string
  category: string
  orderId: string
  products: SelectedTreatmentProduct[]
  userId: string
  bmiData?: saveBmiAnswerProps
}

export const updateTreatment = async ({
  category,
  orderId,
  products,
  treatmentPlanId,
  userId,
  bmiData,
}: UpdateTreatmentContext): Promise<boolean> => {
  const accessToken = getToken({ fromQueryParams: false })
  const axiosInstance = await createAxiosInstance({ token: accessToken })
  if (products.length === 0) {
    return false
  }
  const { data } = await axiosInstance.patch<boolean>(
    `/treatments/${treatmentPlanId}`,
    {
      metadata: {
        category,
        orderId,
        ...(bmiData && Object.keys(bmiData).length > 0 ? { bmiData } : {}),
      },
      products,
      userId,
    },
  )

  return data
}
