import { getToken } from '../utils'
import { createAxiosInstance } from '../utils/createAxiosInstance'

export type saveBmiAnswerProps = {
  bmi?: string
  height?: string
  weight?: string
}
export const saveBmiAnswer = async ({
  bmi,
  height,
  weight,
}: saveBmiAnswerProps) => {
  const accessToken = getToken({ fromQueryParams: false })
  const axiosInstance = await createAxiosInstance({ token: accessToken })
  const { data } = await axiosInstance.post<boolean>(`/bmi`, {
    bmi,
    height,
    weight,
  })
  return data
}
