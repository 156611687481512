import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

import { useFinalizeConsultationMutation } from '../../queries'
import { useBmiMutation } from '../../queries/medical/useBmiMutation'
import { VisitStatus } from '../../services/medical/types'
import { bmiCalculator } from '../../services/utils/bmi'
import { Button, Card, Input, List, Text } from '../../ui'
import { EndConsultButton } from '../Plato/EndConsultButton'
import { NoShowConsultButton } from '../Plato/NoShowConsultButton'
import { usePlatoContext } from '../Plato/PlatoProvider'
import { StartConsultButton } from '../Plato/StartConsultButton'
import { useTreatmentContext } from './TreatmentProvider'

export const TreatmentPlanActions = () => {
  const {
    order,
    status,
    treatment: existingTreatment,
    startedAt,
    endedAt,
    isNoShow,
    markedNoShowAt,
  } = usePlatoContext()
  const { error, mutate, isLoading } = useFinalizeConsultationMutation()
  const { patient } = usePlatoContext()
  const {
    treatment,
    createTreatmentPlan,
    updateTreatmentPlan,
    isLoading: isCreatingPlan,
    hasTreatmentPlan,
    height,
    weight,
    setWeight,
    setHeight,
  } = useTreatmentContext()

  const { mutate: bmiMutate } = useBmiMutation()

  const hasWeightLossInTreatmentCart = useMemo(
    () => treatment.some(item => item.category.name === 'Weight Loss'),
    [treatment],
  )

  const [reason, setReason] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [color, setColor] = useState<'danger' | undefined>(undefined)

  const onChange = (text: string) => {
    setReason(text)
    if (errorMessage) {
      setErrorMessage('')
      setColor(undefined)
    }
  }

  useEffect(() => {
    if (!error) return

    const { message } = error as Error

    if (treatment.length === 0) {
      setErrorMessage(message)
      setColor('danger')
    } else {
      setErrorMessage('')
      setColor(undefined)
      error === null
    }
  }, [error, treatment])

  const createPlanHandler = () => {
    let bmi
    if (hasWeightLossInTreatmentCart) {
      if (!(Boolean(+height) && Boolean(+weight))) {
        alert('Please fill the Height and Weight')
        return
      } else {
        bmi = String(bmiCalculator(+weight, +height))

        if (bmi === undefined || isNaN(+bmi)) {
          alert('Invalid Height or Weight values')
          return
        }

        bmiMutate(
          {
            bmi,
            height,
            weight,
          },
          {
            onSuccess: () => {
              setWeight('')
              setHeight('')
            },
          },
        )
      }
    }

    if (existingTreatment) {
      updateTreatmentPlan(order.orderId, existingTreatment.id, patient.userId, {
        ...(height ? { height } : {}),
        ...(weight ? { weight } : {}),
        ...(bmi ? { bmi } : {}),
      })
      return
    }

    createTreatmentPlan(order.orderId, {
      ...(height ? { height } : {}),
      ...(weight ? { weight } : {}),
      ...(bmi ? { bmi } : {}),
    })
  }

  return (
    <Card padding="p-0">
      <List>
        {treatment.length === 0 && (
          <List>
            <Text isBold size="small">
              Plan or Reason for ending consultations without plan
            </Text>
            <Input
              border-radius="rounded-full"
              color={color}
              message={errorMessage}
              onChange={event => onChange(event.target.value)}
            />
          </List>
        )}
        <List
          alignItems="items-center"
          className="my-4"
          justifyContent="justify-center"
        >
          <Button
            isFullWidth
            className="bg-neutral6 text-base"
            disabled={isCreatingPlan}
            size="small"
            type="button"
            onClick={createPlanHandler}
          >
            {!isCreatingPlan &&
              (existingTreatment ? 'Update Plan' : 'Create Plan')}

            {isCreatingPlan && 'Creating...'}
          </Button>
          {(status === VisitStatus.Registered ||
            status === VisitStatus.Rejected) && (
            <Button
              isFullWidth
              className="bg-red1 text-base text-black"
              disabled={isLoading}
              size="small"
              type="button"
              onClick={() => {
                mutate({
                  consultationType: 'treatment-plan',
                  order,
                  orderRef: order.orderRef,
                  reason: reason,
                  status: hasTreatmentPlan,
                })
              }}
            >
              {!isLoading && 'End Consultation'}
              {isLoading && 'Ending Consultation'}
            </Button>
          )}
          {!startedAt && <StartConsultButton />}
          {startedAt && !endedAt && <EndConsultButton />}
          {!isNoShow &&
            !markedNoShowAt &&
            status !== VisitStatus.Approved &&
            status !== VisitStatus.Rejected && <NoShowConsultButton />}

          {markedNoShowAt ? (
            <span className="text-sm">
              No Show Marked at{' '}
              {dayjs(markedNoShowAt).format('MMMM D, YYYY h:mm A')}
            </span>
          ) : null}
        </List>
      </List>
    </Card>
  )
}
