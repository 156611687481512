import { useMutation } from '@tanstack/react-query'

import { updateTreatment } from '../../services'

export const useUpdateTreatmentMutation = () => {
  const { error, mutate, ...mutation } = useMutation(updateTreatment)

  return {
    error,
    mutate,
    ...mutation,
  }
}
