import { Text } from '../../ui'
import { useTreatmentContext } from '../CartBuilder/TreatmentProvider'

export const BmiCalculator = () => {
  const { height, weight, setHeight, setWeight } = useTreatmentContext()
  return (
    <div className="px-6 grid grid-cols-[0.3fr_1fr] gap-4">
      <Text isBold className="mr-4" size="large">
        Enter Patient's Height (in cm)
      </Text>
      <input
        border-radius="rounded-full"
        className=" px-4 py-2 w-32"
        min="0"
        type="number"
        value={height}
        onChange={event => setHeight(event.target.value)}
      />
      <Text isBold className="mr-4" size="large">
        Enter Patient's Weight (in kg)
      </Text>
      <input
        border-radius="rounded-full"
        className="px-4 py-2 w-32"
        min="0"
        type="number"
        value={weight}
        onChange={event => setWeight(event.target.value)}
      />
    </div>
  )
}
